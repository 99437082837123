import React from 'react';
import './TopNavigationBar.scss';
import StateBar from "../StateBar/StateBar";
import { UnderlineTextButton } from "../../Common/UnderlineTextButton/UnderlineTextButton";
import { PopupWindowType } from "../../../data/enums/PopupWindowType";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { updateActivePopupType, updateProjectData } from "../../../store/general/actionCreators";
import { ProjectData } from "../../../store/general/types";
import { Settings } from "../../../settings/Settings";

interface IProps {
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    updateProjectData: (projectData: ProjectData) => any;
    projectData: ProjectData;
}

const TopNavigationBar: React.FC<IProps> = ({ updateActivePopupType, updateProjectData, projectData }) => {
    return (
        <div className="TopNavigationBar">
            <StateBar />
            <div className="TopNavigationBarWrapper">
                <div
                    className="Header"
                    onClick={() => updateActivePopupType(PopupWindowType.EXIT_PROJECT)}
                >
                    <img
                        draggable={false}
                        alt={"crowd sourcing app"}
                        src={"/img/logo_app.png"}
                    />
                        Crowd Sourcing App {Settings.VERSION_APP}
                    </div>
                <div className="ProjectName">
                    <p>You are working on <span className="name">{projectData.name}</span> project</p>
                </div>
                <div className="NavigationBarGroupWrapper">
                <UnderlineTextButton
                        label={"STATS"}
                        under={true}
                        onClick={() => updateActivePopupType(PopupWindowType.LOAD_STATS)}
                    />
                    <UnderlineTextButton
                        label={"USER GUIDE"}
                        under={true}
                        onClick={() => window.open(Settings.USER_GUIDE_REFERENCE, '_blank')}
                        style={{color:"#2af598"}}
                    />
                    <UnderlineTextButton
                        label={"MORE IMAGES"}
                        under={true}
                        onClick={() => updateActivePopupType(PopupWindowType.LOAD_IMAGES)}
                    />
                    <UnderlineTextButton
                        label={"START NEW BATCH"}
                        under={true}
                        onClick={() => updateActivePopupType(PopupWindowType.EXIT_PROJECT)}
                    />
                    <UnderlineTextButton
                        label={"EXPORT LABELS"}
                        under={true}
                        onClick={() => updateActivePopupType(PopupWindowType.EXPORT_LABELS)}
                    />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateActivePopupType,
    updateProjectData
};

const mapStateToProps = (state: AppState) => ({
    projectData: state.general.projectData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopNavigationBar);