import { ContextType } from "../../../data/enums/ContextType";
import './EditorTopNavigationBar.scss';
import React from "react";
import classNames from "classnames";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { updateImageDragModeStatus } from "../../../store/general/actionCreators";
import { GeneralSelector } from "../../../store/selectors/GeneralSelector";
import { ViewPointSettings } from "../../../settings/ViewPointSettings";
import { IconButton } from "../../Common/IconButton/IconButton";
import { ViewPortActions } from "../../../logic/actions/ViewPortActions";
import { LabelType } from "../../../data/enums/LabelType";
import { ISize } from "../../../interfaces/ISize";

interface IProps {
    activeContext: ContextType;
    updateImageDragModeStatus: (imageDragMode: boolean) => any;
    imageDragMode: boolean;
    activeLabelType: LabelType;
}

const EditorTopNavigationBar: React.FC<IProps> = ({ activeContext, updateImageDragModeStatus, imageDragMode, activeLabelType }) => {
    const buttonSize: ISize = { width: 30, height: 30 };
    const buttonPadding: number = 10;

    const getClassName = () => {
        return classNames(
            "EditorTopNavigationBar",
            {
                "with-context": activeContext === ContextType.EDITOR
            }
        );
    };

    const imageDragOnClick = () => {
        if (imageDragMode) {
            updateImageDragModeStatus(!imageDragMode);
        }
        else if (GeneralSelector.getZoom() !== ViewPointSettings.MIN_ZOOM) {
            updateImageDragModeStatus(!imageDragMode);
        }
    };

    return (
        <div className={getClassName()}>
            <div className="ButtonWrapper">
                <IconButton
                    iconClass={"fas fa-search-plus"}
                    buttonSize={buttonSize}
                    padding={buttonPadding}
                    onClick={() => ViewPortActions.zoomIn()}
                />
                <IconButton
                    iconClass={"fas fa-search-minus"}
                    buttonSize={buttonSize}
                    padding={buttonPadding}
                    onClick={() => ViewPortActions.zoomOut()}
                />
                <IconButton
                    iconClass={"fab fa-rev"}
                    buttonSize={buttonSize}
                    padding={buttonPadding}
                    onClick={() => ViewPortActions.setDefaultZoom()}
                />
                <IconButton
                    iconClass={"fas fa-expand"}
                    buttonSize={buttonSize}
                    padding={buttonPadding}
                    onClick={() => ViewPortActions.setOneForOneZoom()}
                />
            </div>
            <div className="ButtonWrapper">
                <IconButton
                    iconClass={"far fa-hand-paper"}
                    buttonSize={buttonSize}
                    padding={buttonPadding}
                    onClick={imageDragOnClick}
                />
            </div>
        </div>
    )
};

const mapDispatchToProps = {
    updateImageDragModeStatus
};

const mapStateToProps = (state: AppState) => ({
    activeContext: state.general.activeContext,
    imageDragMode: state.general.imageDragMode,
    activeLabelType: state.labels.activeLabelType
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditorTopNavigationBar);