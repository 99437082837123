// This class is intended to create IconButton Objects.
// This object provides an HTML render using font awesome icons.

import * as React from 'react';
import { ISize } from "../../../interfaces/ISize";
import './IconButton.scss';
import classNames from "classnames";
import { LegacyRef } from "react";

export interface IconButtonProps extends React.HTMLProps<HTMLDivElement> {
    buttonSize: ISize,
    padding?: number,
    href?: string,
    iconClass: string,
    onClick?: () => any,
    style?: React.CSSProperties,
    externalClassName?: string,
    iconColorsClassName?: string,
}

export const IconButton = React.forwardRef((props: IconButtonProps, ref: LegacyRef<HTMLDivElement>) => {
    const { buttonSize, padding, href, iconClass, onClick, style, externalClassName, iconColorsClassName } = props;
    const imagePadding: number = !!padding ? padding : 10;
    const iconStandardColor: string = "IconStandardColor";

    const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        !!onClick && onClick();
    };

    const buttonStyle: React.CSSProperties = {
        ...style,
        width: buttonSize.width,
        height: buttonSize.height,
        lineHeight: buttonSize.height + "px"
    };

    const imageStyle: React.CSSProperties = {
        maxWidth: buttonSize.width - imagePadding,
        maxHeight: buttonSize.height - imagePadding
    };

    const getClassName = () => {
        return classNames(
            "IconButton",
            externalClassName
        );
    };

    const getIconClassName = () => {
        return classNames(
            iconClass,
            !iconColorsClassName ? iconStandardColor : iconColorsClassName
        );
    };

    return (
        <div
            className={getClassName()}
            style={buttonStyle}
            onClick={onClickHandler}
            ref={ref}
        >
            {!!href && <a href={href} style={imageStyle} target="_blank" rel="noopener noreferrer">
                <i className={getIconClassName()}></i>
            </a>}
            {!href && <i className={getIconClassName()}></i>}
        </div>
    );
});