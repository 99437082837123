import React from 'react'
import './LoadStatPopup.scss'
//import {AppState} from "../../../store";
import {connect} from "react-redux";
//import {addImageData} from "../../../store/labels/actionCreators";
import {TextButton} from "../../Common/TextButton/TextButton";
import {PopupActions} from "../../../logic/actions/PopupActions";
import configS3 from '../../../data/configS3.json';

interface IProps {
    //addImageData: (imageData: ImageData[]) => any;
    projectName: string;
}

interface IState {
    totalReactPackages: {};
}

class LoadStatPopup extends React.Component<IProps, IState>  {
    constructor(props) {
        super(props);
        this.state = {
            totalReactPackages: {
                content: {
                    totalImages: null,
                    imagesByCategory: []
                }
            }
        };
    }

    onAccept = () => {
        PopupActions.close();
    };

     onReject = () => {
        PopupActions.close();
    };

    componentDidMount() {
        // retrieve statisitacl data from the server
        // GET request using fetch with async/await
        let projectName = this.props.projectName
        let S3Storage = configS3[projectName]
        let awsEndpoint = 'https://agpurgcnf9.execute-api.eu-west-1.amazonaws.com/default/labelization-platform/stats'
        //let awsEndpoint = '//127.0.0.1:5000/labelization-platform/stats'
        let queryString = `${awsEndpoint}?project=${projectName}&bucket=${S3Storage}`
        fetch(queryString)
            .then(response => response.json())
            .then(data => this.setState({ totalReactPackages: data }));
    }

    iterateElement = () => {
        return this.state.totalReactPackages['content']['imagesByCategory'].map((value) => 
            <p key={value.toString()}>{value.observations} observations belong to {value.label} category</p>
)
    }

    getStatisticalContent = () => {
            return <>
                <p>Total number of images:{this.state.totalReactPackages['content']['totalImages']}</p>
                {this.iterateElement()}
            </>;
    };

    renderContent = () => {
        
        return(<div className="LoadStatPopupContent">
            <i className="fas fa-chart-bar fa-5x" style={{color: "white"}}></i>
            <div className="DropZone">
                {this.state.totalReactPackages && this.getStatisticalContent()}
            </div>
        </div>);
    };

    render() {
        return(
        <div className="StatPopup">
        <div className="Header">
            Basic stats
        </div>
        <div className="Content">
            {this.renderContent()}
        </div>
        <div className="Footer">
            <TextButton
                label= "Close"
                onClick={this.onAccept}
                externalClassName={"accept"}
            />
        </div>
    </div>
    );
        }
}

// const mapDispatchToProps = {
//     addImageData
// };

// const mapStateToProps = (state: AppState) => ({});

export default connect(
//     mapStateToProps,
//     mapDispatchToProps
)(LoadStatPopup);