import React, { useState } from 'react'
import './ImagesDropZone.scss';
import { useDropzone } from "react-dropzone";
import { TextButton } from "../../Common/TextButton/TextButton";
import { SelectButton } from "../../Common/SelectButton/SelectButton";
import { ImageData } from "../../../store/labels/types";
import { connect } from "react-redux";
import { addImageData, updateActiveImageIndex } from "../../../store/labels/actionCreators";
import { AppState } from "../../../store";
import { ProjectType } from "../../../data/enums/ProjectType";
import { FileUtil } from "../../../utils/FileUtil";
import { updateActivePopupType, updateProjectData } from "../../../store/general/actionCreators";
import { AcceptedFileType } from "../../../data/enums/AcceptedFileType";
import { ProjectData } from "../../../store/general/types";
import { LabelName } from "../../../store/labels/types";
import { LabelUtil } from "../../../utils/LabelUtil";
import { updateLabelNames } from "../../../store/labels/actionCreators";
import { PopupActions } from "../../../logic/actions/PopupActions";
import config from '../../../data/config.json';



interface IProps {
    updateActiveImageIndex: (activeImageIndex: number) => any;
    addImageData: (imageData: ImageData[]) => any;
    updateProjectData: (projectData: ProjectData) => any;
    updateLabels: (labels: LabelName[]) => any;
    projectData: ProjectData;
}


const ImagesDropZone: React.FC<IProps> = ({ updateActiveImageIndex, addImageData, updateProjectData, updateLabels, projectData }) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: AcceptedFileType.IMAGE
    });
    const [labelsList, setLabelsList] = useState([]);
    const [invalidFileLoadedStatus, setInvalidFileLoadedStatus] = useState(false);
    let options = [];
    for (let k in config) {
        options.push({ value: k, label: k });
    }


    const onSuccess = (labelsList: string[]) => {
        setLabelsList(labelsList);
        setInvalidFileLoadedStatus(false);
    };

    if (!projectData.name) {
        projectData.name = options[0].value;
    }

    const startEditor = (projectType: ProjectType) => {
        if (acceptedFiles.length > 0) {
            updateProjectData({
                ...projectData,
                type: projectType
            });
            // Load text with labels
            onSuccess(config[projectData.name])
            updateLabels(config[projectData.name].map((name: string) => LabelUtil.mapNamesToLabelNames(name)));


            // Go to "i go on my own"
            updateActiveImageIndex(0);
            addImageData(acceptedFiles.map((fileData: File) => FileUtil.mapFileDataToImageData(fileData)));
            PopupActions.close();
            //updateActivePopupType(PopupWindowType.INSERT_LABEL_NAMES);
        }
    };

    const getDropZoneContent = () => {
        if (acceptedFiles.length === 0)
            return <>
                <input {...getInputProps()} />
                <i className="fas fa-parachute-box fa-7x"></i>
                <p className="extraBold">Drop some images</p>
                <p>or</p>
                <p className="extraBold">Click here to select them</p>
            </>;
        else if (acceptedFiles.length === 1)
            return <>
                <i className="fas fa-box fa-7x"></i>
                <p className="extraBold">1 image loaded</p>
            </>;
        else
            return <>
                <input {...getInputProps()} />
                <i key={1} className="fas fa-box fa-7x"></i>
                <p key={2} className="extraBold">{acceptedFiles.length} images loaded</p>
            </>;
    };

    // Catch value from select dropdown menu
    const onChangeHandler = (event: React.FormEvent<HTMLSelectElement>) => {
        projectData.name = event.currentTarget.value;
    };

    return (
        <div className="ImagesDropZone">
            <div {...getRootProps({ className: 'DropZone' })}>
                {getDropZoneContent()}
            </div>
            <div className="DropZoneButtons">
                <SelectButton
                    options={options}
                    onChange={onChangeHandler}
                    defaultText={"Choose a project"} />
                <TextButton
                    label={"Start labelling!"}
                    isDisabled={!acceptedFiles.length}
                    onClick={() => startEditor(ProjectType.OBJECT_DETECTION)} />
            </div>
        </div>
    )
};

const mapDispatchToProps = {
    updateActiveImageIndex,
    addImageData,
    updateProjectData,
    updateActivePopupType,
    updateLabels: updateLabelNames
};

const mapStateToProps = (state: AppState) => ({
    projectData: state.general.projectData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagesDropZone);