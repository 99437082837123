export enum PopupWindowType {
    LOAD_LABEL_NAMES = "LOAD_LABEL_NAMES",
    UPDATE_LABEL_NAMES = "UPDATE_LABEL_NAMES",
    SUGGEST_LABEL_NAMES = "SUGGEST_LABEL_NAMES",
    LOAD_IMAGES = "LOAD_IMAGES",
    LOAD_STATS = "LOAD_STATS",
    LOAD_AI_MODEL = "LOAD_AI_MODEL",
    EXPORT_LABELS = "EXPORT_LABELS",
    EXPORT_LABELS_DONE = "EXPORT_LABELS_DONE",
    INSERT_LABEL_NAMES = 'INSERT_LABEL_NAMES',
    EXIT_PROJECT = 'EXIT_PROJECT',
    LOADER = 'LOADER'
}