import React from 'react';
import './PopupView.scss';
import {PopupWindowType} from "../../data/enums/PopupWindowType";
import {AppState} from "../../store";
import {connect} from "react-redux";
import LoadLabelsPopup from "./LoadLabelNamesPopup/LoadLabelNamesPopup";
import ExportLabelPopup from "./ExportLabelsPopup/ExportLabelPopup";
import ExportLabelPopupDone from "./ExportLabelsPopupDone/ExportLabelPopupDone";
import InsertLabelNamesPopup from "./InsertLabelNamesPopup/InsertLabelNamesPopup";
import ExitProjectPopup from "./ExitProjectPopup/ExitProjectPopup";
import LoadMoreImagesPopup from "./LoadMoreImagesPopup/LoadMoreImagesPopup";
import LoadStatPopup from "./LoadStatPopup/LoadStatPopup";
import {Settings} from "../../settings/Settings";
import ClipLoader from "react-spinners/ClipLoader";

interface IProps {
    activePopupType: PopupWindowType;
    projectName: string;
}

const PopupView: React.FC<IProps> = ({activePopupType, projectName}) => {

    const selectPopup = () => {
        switch (activePopupType) {
            case PopupWindowType.LOAD_LABEL_NAMES:
                return <LoadLabelsPopup/>;
            case PopupWindowType.EXPORT_LABELS:
                return <ExportLabelPopup
                        projectName = {projectName}
                />;
            case PopupWindowType.EXPORT_LABELS_DONE:
                return <ExportLabelPopupDone/>;
            case PopupWindowType.INSERT_LABEL_NAMES:
                return <InsertLabelNamesPopup
                    isUpdate={false}
                />;
            case PopupWindowType.UPDATE_LABEL_NAMES:
                return <InsertLabelNamesPopup
                    isUpdate={true}
                />;
            case PopupWindowType.EXIT_PROJECT:
                return <ExitProjectPopup/>;
            case PopupWindowType.LOAD_IMAGES:
                return <LoadMoreImagesPopup/>;
            case PopupWindowType.LOAD_STATS:
                return <LoadStatPopup
                projectName = {projectName}
                />;
            case PopupWindowType.LOADER:
                return <ClipLoader
                    size={50}
                    color={Settings.SECONDARY_COLOR}
                    loading={true}
                />;
            default:
                return null;
        }
    };

    return (
        activePopupType && <div className="PopupView">
            {selectPopup()}
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    activePopupType: state.general.activePopupType
});

export default connect(
    mapStateToProps
)(PopupView);