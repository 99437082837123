import React, { useState } from 'react';
import './MainView.scss';
import { TextButton } from "../Common/TextButton/TextButton";
import classNames from 'classnames';
import { ISize } from "../../interfaces/ISize";
import { ImageButton } from "../Common/ImageButton/ImageButton";
import { IconButton } from "../Common/IconButton/IconButton";
import { ISocialMedia, SocialMediaData } from "../../data/info/SocialMediaData";
import { HeaderData } from "../../data/info/HeaderData";
import ImagesDropZone from "./ImagesDropZone/ImagesDropZone";
import { Settings } from "../../settings/Settings";

const MainView: React.FC = () => {
    const [projectInProgress, setProjectInProgress] = useState(false);
    const [projectCanceled, setProjectCanceled] = useState(false);

    const startProject = () => {
        setProjectInProgress(true);
    };

    const endProject = () => {
        setProjectInProgress(false);
        setProjectCanceled(true);
    };

    const getClassName = () => {
        return classNames(
            "MainView", {
            "InProgress": projectInProgress,
            "Canceled": !projectInProgress && projectCanceled
        }
        );
    };

    const getSocialMediaButtons = (size: ISize) => {
        return SocialMediaData.map((data: ISocialMedia, index: number) => {
            return <IconButton
                key={index}
                buttonSize={size}
                iconClass={data.faIconClass}
                href={data.href}
            />
        });
    };

    const getHeaderContent = () => {
        var content = HeaderData[0];
        return <div>
            <h1>{content.headerText}</h1>
            <p>{content.contentText}</p>
        </div>

    }

    return (
        <div className={getClassName()}>
            <div className="LeftColumn">
                <div className={"LogoWrapper"}>
                    <img
                        draggable={false}
                        alt={"main-logo"}
                        src={"img/logo_header.png"}
                    />
                </div>

                <div className="LandingWrapper">
                    {getHeaderContent()}
                    <div className="devSection">
                        <p> Links to Make Sense App</p>
                        {getSocialMediaButtons({ width: 30, height: 30 })}
                    </div>
                    <div className="StartButtonWrapper">
                        {!projectInProgress && <TextButton
                            label={"Get Started"}
                            onClick={startProject}
                        />}
                    </div>

                    <div>
                        <h1>Improved by:</h1>
                        <div className="PartnerSection">
                            <ImageButton
                                buttonSize={{ width: 120, height: 120 }}
                                image="/img/legia_squad.png"
                                imageAlt="Legiasquad website"
                                href="https://www.legiasquad.com/"
                            />
                            <ImageButton
                                buttonSize={{ width: 120, height: 120 }}
                                image="/img/nrb.png"
                                imageAlt="NRB website"
                                href="https://www.nrb.be/"
                            />
                        </div>
                    </div>
                </div>

                <div className="VerticalDivider">
                    <div className="VerticalDividerContent" />
                </div>
                {projectInProgress && <TextButton
                    label={"Go Back"}
                    onClick={endProject}
                    style={{ marginBottom: '10px' }}
                />}
            </div>
            <div className="RightColumn">
                <ImagesDropZone />
            </div>
            <div className="Version">{Settings.VERSION_APP}</div>
        </div>
    );
};

export default MainView;