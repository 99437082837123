import { ImageData, LabelName, LabelRect } from "../../store/labels/types";
import { ImageRepository } from "../imageRepository/ImageRepository";
import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import { findLast } from "lodash";
import configS3 from '../../data/configS3.json';

export class RectLabelsExporter {
    // Function to export to an s3 NOT Yet working  readFile not recognized
    private static async exportToS3(data, csv_data, projectName: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', data);
            formData.append('csv_data', csv_data);
            const requestOptions = {
                method: 'POST',
                headers: {'x-api-key': 'o0k68nvjoO8shEm5oHme38VCTFG3J12Q2KiNUbM2'},
                body: formData
            };

            // const requestOptions = {
            //     method: 'POST',
            //     headers: {'Access-Control-Allow-Origin': '*'},
            //     body: formData
            // };
    
            let S3Storage = configS3[projectName]
            let awsEndpoint = 'https://agpurgcnf9.execute-api.eu-west-1.amazonaws.com/default/labelization-platform/upload'
            //let awsEndpoint = 'http://127.0.0.1:5000/labelization-platform/upload'
            let queryString = `${awsEndpoint}?project=${projectName}&bucket=${S3Storage}`
            fetch(queryString, requestOptions)
                 .then(response => console.log(response.status))//json())
                 .then(data => console.log(data))
                 .then((d) => {
                     resolve(d);
                 });
        });
        
    }

    // Our custom export to generate a txt file by image
    public static async export(projectName: string): Promise<void> {
        return new Promise(async (resolve, reject) => {

        let imagesData = LabelsSelector.getImagesData();
        for (const img of imagesData) {
            const fileContent: string = RectLabelsExporter.wrapRectLabelsIntoCSV(img);
            const csv_data = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
            await RectLabelsExporter.exportToS3(img.fileData, csv_data, projectName)
            console.log("data export")
        }
        console.log('all tested');
        resolve();
        });
    }


    private static wrapRectLabelsIntoCSV(imageData: ImageData): string {
        if (imageData.labelRects.length === 0 || !imageData.loadStatus)
            return null;

        const image: HTMLImageElement = ImageRepository.getById(imageData.id);
        const labelNames: LabelName[] = LabelsSelector.getLabelNames();
        const csvHeader = "Label, X, Y, Rect_width, Rect_height, Width, Height, Free_text \n"
        const labelRectsString: string[] = imageData.labelRects.map((labelRect: LabelRect) => {
            const labelName: LabelName = findLast(labelNames, { id: labelRect.labelId });
            const labelFields = !!labelName ? [
                labelName.name,
                Math.round(labelRect.rect.x) + "",
                Math.round(labelRect.rect.y) + "",
                Math.round(labelRect.rect.width) + "",
                Math.round(labelRect.rect.height) + "",
               // imageData.fileData.name,
                image.width + "",
                image.height + "",
                labelRect.free_text + ""
            ] : [];
            return labelFields.join(",")
        });
        return csvHeader + labelRectsString.join("\n");
    }
}