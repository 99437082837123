import React from 'react'
import './ExportLabelPopupDone.scss'
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { PopupActions } from "../../../logic/actions/PopupActions";
import { updateActivePopupType } from "../../../store/general/actionCreators";
import { PopupWindowType } from "../../../data/enums/PopupWindowType";
import { TextButton } from "../../Common/TextButton/TextButton";

interface IProps {
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
}

const ExportLabelPopupDone: React.FC<IProps> = ({ updateActivePopupType }) => {

    const onReject = () => {
        PopupActions.close();
    };

    return (
        <div className="ExportPopupLabelDone">
            <div className="Header">
                Export success !
            </div>
            <div className="Content">
                <div className="Message">
                    Your labelled data has been uploaded on our server successfully!
                </div>
                <br></br>
                <div className="Thanks">
                    Thanks for helping us!
                </div>
            </div>
            <div className="Footer">
                <TextButton
                    label="Exit"
                    onClick={onReject}
                    externalClassName={"accept"}
                />
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateActivePopupType
};

const mapStateToProps = (state: AppState) => ({
    imagesData: state.labels.imagesData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportLabelPopupDone);