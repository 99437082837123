export interface IHeader {
    headerText: string;
    contentText: string;
    imageSrc: string;
    imageAlt: string;
}

export const HeaderData: IHeader[] = [
    {
        headerText: "Crowdsourcing App",
        contentText: "This crowdsourcing application is based on an open-source project called Make Sense. " +
            "This project was developed to have a user friendly application allowing people to labelize content easily. " +
            "In that perspective, since we need to have a similar tool in our different AI and machine learning projects, we decided to " +
            "build our own tool on the very good job already done by the Make Sense team.",
        imageSrc: "/img/medium-logo.png",
        imageAlt: "Medium Logo",
    },
    {
        headerText: "Crowd Sourcing App",
        contentText: "/img/github-logo.png",
        imageSrc: "/img/medium-logo.png",
        imageAlt: "Medium Logo",
    },
];