import React from 'react';
import { ISize } from "../../../../interfaces/ISize";
import './LabelInputField.scss';
import classNames from "classnames";
import { IconButton } from "../../../Common/IconButton/IconButton";
import { IRect } from "../../../../interfaces/IRect";
import { IPoint } from "../../../../interfaces/IPoint";
import { RectUtil } from "../../../../utils/RectUtil";
import { AppState } from "../../../../store";
import { connect } from "react-redux";
import { updateActiveLabelId, updateHighlightedLabelId} from "../../../../store/labels/actionCreators";
import Scrollbars from 'react-custom-scrollbars';
import { EventType } from "../../../../data/enums/EventType";
import { LabelName } from "../../../../store/labels/types";
import { LabelsSelector } from "../../../../store/selectors/LabelsSelector";
import { PopupWindowType } from "../../../../data/enums/PopupWindowType";
import { updateActivePopupType } from "../../../../store/general/actionCreators";
import {ImageData} from "../../../../store/labels/types";
import {LabelRect} from "../../../../store/labels/types";
import {LabelStatus} from "../../../../data/enums/LabelStatus";

interface IProps {
    size: ISize;
    isActive: boolean;
    isHighlighted: boolean;
    id: string;
    value: LabelName;
    options: LabelName[];
    imageData: ImageData;
    onDelete: (id: string) => any;
    onSelectLabel: (labelRectId: string, labelNameId: string) => any;
    updateHighlightedLabelId: (highlightedLabelId: string) => any;
    updateActiveLabelId: (highlightedLabelId: string) => any;
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    updateImageDataById: (id: string, imageData: ImageData) => any;
}

interface IState {
    animate: boolean;
    isOpen: boolean;
    extraText: string;
}

class LabelInputField extends React.Component<IProps, IState> {
    private dropdownOptionHeight: number = 35;
    private dropdownOptionCount: number = 6;
    private dropdownMargin: number = 4;
    private dropdownLabel: HTMLDivElement;
    private dropdown: HTMLDivElement;
    private activeImageData: ImageData;

    public constructor(props) {
        super(props);
        this.state = {
            animate: false,
            isOpen: false,
            extraText: ''
        }
        this.activeImageData = LabelsSelector.getActiveImageData();
        console.log('active image data: ' + this.activeImageData);
    }

    public componentDidMount(): void {
        requestAnimationFrame(() => {
            this.setState({ animate: true });
        });
    }

    private getClassName() {
        return classNames(
            "LabelInputField",
            {
                "loaded": this.state.animate,
                "active": this.props.isActive,
                "highlighted": this.props.isHighlighted
            }
        );
    }

    private openDropdown = () => {
        if (LabelsSelector.getLabelNames().length === 0) {
            this.props.updateActivePopupType(PopupWindowType.UPDATE_LABEL_NAMES);
        } else {
            this.setState({ isOpen: true });
            window.addEventListener(EventType.MOUSE_DOWN, this.closeDropdown);
        }
    };

    private closeDropdown = (event: MouseEvent) => {
        const mousePosition: IPoint = { x: event.clientX, y: event.clientY };
        const clientRect = this.dropdown.getBoundingClientRect();
        const dropDownRect: IRect = {
            x: clientRect.left,
            y: clientRect.top,
            width: clientRect.width,
            height: clientRect.height
        };

        if (!RectUtil.isPointInside(dropDownRect, mousePosition)) {
            this.setState({ isOpen: false });
            window.removeEventListener(EventType.MOUSE_DOWN, this.closeDropdown)
        }
    };

    private getDropdownStyle = (): React.CSSProperties => {
        const clientRect = this.dropdownLabel.getBoundingClientRect();
        const height: number = Math.min(this.props.options.length, this.dropdownOptionCount) * this.dropdownOptionHeight;
        const style = {
            width: clientRect.width,
            height: height,
            left: clientRect.left
        };

        if (window.innerHeight * 2 / 3 < clientRect.top)
            return Object.assign(style, { top: clientRect.top - this.dropdownMargin - height });
        else
            return Object.assign(style, { top: clientRect.bottom + this.dropdownMargin });
    };

    private getDropdownOptions = () => {
        const onClick = (id: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            this.setState({ isOpen: false });
            window.removeEventListener(EventType.MOUSE_DOWN, this.closeDropdown);
            this.props.onSelectLabel(this.props.id, id);
            console.log('propsid: ' + this.props.id);
            console.log('id: ' + id);
            this.props.updateHighlightedLabelId(null);
            this.props.updateActiveLabelId(this.props.id);
            
            console.log('updated highlighted label');
            
            event.stopPropagation();
        };

        return this.props.options.map((option: LabelName) => {
            return <div
                className="DropdownOption"
                key={option.id}
                style={{ minHeight: this.dropdownOptionHeight }}
                onClick={(event) => onClick(option.id, event)}
            >
                {option.name}
            </div>
        })
    };

    private mouseEnterHandler = () => {
        this.props.updateHighlightedLabelId(this.props.id);
    };

    private mouseLeaveHandler = () => {
        this.props.updateHighlightedLabelId(null);
    };

    private onClickHandler = () => {
        this.props.updateActiveLabelId(this.props.id);
    };

    //Edit the value of an input text type based on the Onchange Handler
    private setOnChangeTextValue = (event, labelId) => {
        console.log('changing ' + event.target.value);
        const newImageData = {
            ...this.props.imageData,
            labelRects: this.props.imageData.labelRects
                .map((labelRect: LabelRect) => {
                if (labelRect.id === labelId) {
                    console.log('Indeed we change');
                    console.log('Labelid: '  + labelRect.labelId);
                    return {
                        ...labelRect,
                        status: LabelStatus.ACCEPTED,
                        free_text: event.target.value
                    }
                } else {
                    return labelRect
                }
            })
        };
            this.activeImageData = newImageData;
            this.props.updateImageDataById(this.activeImageData.id, newImageData);
            // updateActiveLabelNameId(labelNameId);
        this.setState({ extraText: event.target.value });
    }

    // Change the display view of an element based on its Id
    // from the viewType defined with none display type and vise versa.
    private changeDisplay = (resId, viewType: string) => {
        document.getElementById(resId).style.display = document.getElementById(resId).style.display === 'none' ? viewType : 'none';
    }

    public render() {
        const { size, id, value, onDelete } = this.props;
        return (
            <div
                className={this.getClassName()}
                style={{
                    width: size.width,
                    height: "auto",
                }}
                key={id}
                onMouseEnter={this.mouseEnterHandler}
                onMouseLeave={this.mouseLeaveHandler}
                onClick={this.onClickHandler}
            >
                <div
                    className="LabelInputFieldWrapper"
                >
                    <div className="Marker" />
                    <div className="Content">
                        <div className="ContentWrapper">
                            <div className="DropdownLabel"
                                ref={ref => this.dropdownLabel = ref}
                                onClick={this.openDropdown}
                            >
                                {value ? value.name : "Select label"}
                            </div>
                            {this.state.isOpen && <div
                                className="Dropdown"
                                style={this.getDropdownStyle()}
                                ref={ref => this.dropdown = ref}
                            >
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                                >
                                    <div>
                                        {this.getDropdownOptions()}
                                    </div>
                                </Scrollbars>

                            </div>}
                        </div>
                        <div className="ContentWrapper">
                            <IconButton
                                iconClass={"fas fa-edit"}
                                iconColorsClassName={"FreeTextLabel"}
                                buttonSize={{ width: 30, height: 30 }}
                                style={{ margin: 0 }}
                                onClick={() => this.changeDisplay(id, 'flex')}
                            />
                            <IconButton
                                iconClass={"fas fa-trash-alt"}
                                iconColorsClassName={"TrashFaIcon"}
                                buttonSize={{ width: 30, height: 30 }}
                                style={{ margin: 0 }}
                                onClick={() => onDelete(id)}
                            />
                        </div>
                        <div className="FreeTextLabel" id={id} style={{ display: "none" }}>
                            <div className="MarkerFreeText" />
                            <input type="text" value={this.state.extraText} onChange={(event) => this.setOnChangeTextValue(event, id)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    updateHighlightedLabelId,
    updateActiveLabelId,
    updateActivePopupType
};

const mapStateToProps = (state: AppState) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabelInputField);