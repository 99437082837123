import React from 'react'
import './ExportLabelPopup.scss'
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { RectLabelsExporter } from "../../../logic/export/RectLabelsExporter";
import { PopupActions } from "../../../logic/actions/PopupActions";
import { updateActivePopupType } from "../../../store/general/actionCreators";
import { PopupWindowType } from "../../../data/enums/PopupWindowType";
import { ImageData } from "../../../store/labels/types";

interface IProps {
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    imagesData: ImageData[];
    projectName: string;
}

const ExportLabelPopup: React.FC<IProps> = ({ updateActivePopupType, projectName, imagesData }) => {
    const onAccept = async () => {
        RectLabelsExporter.export(projectName).then(() => {
            updateActivePopupType(PopupWindowType.EXPORT_LABELS_DONE);
        });
    };

    const onReject = () => {
        PopupActions.close();
    };
    const labeledImages = imagesData.reduce((currentCount: number, currentImage: ImageData) => {
        return currentCount + (currentImage.labelRects.length > 0 ? 1 : 0);
    }, 0);

    const getProgress = () => {
        return (100 * labeledImages) / imagesData.length;
    };

    const check_all_labeled = () => {
        const commonText = "You can upload your data by clicking on the export button " +
        "Depending the the number of images you want to upload this may take a while."
        if (getProgress() < 100) {
            return (<div className="Message">
                {commonText}
                <p style={{ color: "#F45D4C" }}>Pay attention! Some images are not yet labeled <i className="fas fa-thumbs-down"></i></p>
            </div>)
        } else {
            return (<div className="Message">
                {commonText}
                <p style={{ color: "#A1DBB2" }}>All images are labeled <i className="fas fa-thumbs-up"></i></p>
            </div>)
        }
    }

    const renderContent = () => {
        return (<div className="ExportLabelPopupContent">
            <div className="RightContainer">
                {check_all_labeled()}
            </div>
        </div>);
    };

    return (
        <GenericYesNoPopup
            title={"Export your labels"}
            renderContent={renderContent}
            acceptLabel={"Export"}
            onAccept={onAccept}
            rejectLabel={"I'm not ready yet"}
            onReject={onReject}
        />
    );
};

const mapDispatchToProps = {
    updateActivePopupType
};

const mapStateToProps = (state: AppState) => ({
    imagesData: state.labels.imagesData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportLabelPopup);