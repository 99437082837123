import * as React from 'react';
import './SelectButton.scss';
import classNames from "classnames";

interface IProps {
    key?: string;
    options: Array<{ value: string, label: string }>;
    onChange?: any;
    style?: React.CSSProperties;
    defaultText?: string;
}

export const SelectButton = (props: IProps) => {
    const { key, options, onChange, style, defaultText } = props;

    const getOptions = options.map(function (option) {
        return <option value={option.value} key={option.value}>
            {option.label}
        </option>
    });

    const getClassName = () => {
        return classNames("SelectButton");
    };
    return (
        <select className={getClassName()}
            name="projects"
            onChange={onChange}
            key={key}
            style={style}
            defaultValue={options[0].value}>
            <option value="test" disabled>{defaultText}</option>
            {getOptions}
        </select>

    )
};